/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:591a60d1-0b94-4b55-bd17-ea88ea18ae06",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_uy9XTbU6S",
    "aws_user_pools_web_client_id": "10t97oa09s8hu9dvvp2jhqrs2u",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://zptphtcrerdx5hwewv43u223le.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "9066d1e871b942949c8ec1306c043cc4",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "aws_user_files_s3_bucket": "profilepicturestest-test",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
